import React, { useContext } from "react";
import { Link } from "react-router-dom";
import myContext from "../../data/myContext";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";

export default function CartPopup() {
  const context = useContext(myContext);
  const { cartItem, setCartItem } = context;
  console.log(cartItem);

  let subTotal = 0;
  cartItem.map((item) => (subTotal = subTotal + item.count * item.price));
  console.log(subTotal);

  function deleteItem(index) {
    cartItem.splice(index, 1);
    setCartItem(cartItem);
  }

  return (
    <div className="z-50 origin-top-right absolute right-0 mt-7 w-80 md:w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
      <div className="p-3 w-full mx-auto ">
        <div className="flex justify-between items-start ">
          <p className="text-base">
            <span className="font-semibold">{cartItem.length} </span> Item in
            Cart
          </p>

          <div className="text-base">
            <p>Cart Subtotal :</p>
            <p className="font-semibold float-right">${subTotal}</p>
          </div>
        </div>
        <Link to="/cart">
          <button className="bg-black tracking-wider text-white px-4 py-2 rounded-md hover:scale-90 transition my-4 font-semibold w-full">
            Proceed to Checkout
          </button>
        </Link>
      </div>
      <div
        className="py-1 h-28 overflow-y-auto  border"
        // role="menu"
        // aria-orientation="vertical"
        // aria-labelledby="options-menu"
      >
        {/* Your cart items rendering logic goes here */}
        {cartItem.map((item, index) => (
          <div className="p-3 w-full  mx-auto  ">
            <div className="flex gap-2 items-start ">
              <img src={item.image} alt="product-img" className="w-20 h-20" />
              <div className="w-full">
                <p className="truncate w-60 ">{item.name}</p>
                <p>${item.price}</p>
                <div className="flex justify-between w-full ">
                  <div>
                    Qty :{" "}
                    <span className="border border-black py-1 px-3">
                      {item.count}
                    </span>
                  </div>
                  <div className="flex gap-2">
                    <Link to="/cart">
                      <PencilIcon className="h-6 w-6 cursor-pointer" />
                    </Link>
                    <TrashIcon
                      className="h-6 w-6 cursor-pointer"
                      onClick={() => deleteItem(index)}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* 
        <div className='text-base font-bold bg-red-400'>{item.title}hammad</div>
        <div className='text-base mt-4'><span className='font-semibold'>Quantity : </span>{item.count}</div>
        <div className='text-base'><span className='font-bold'>Price :</span> ${item.price}</div>
        <div className='text-base'><span className='font-bold'>Subtotal :</span> ${item.price * item.count}</div>
        <Link to='/cart'>
          <button
            className="bg-black tracking-wider text-white px-4 py-2 rounded-md hover:scale-110 transition my-4 font-semibold"
          >
            Go to Cart
          </button>
        </Link>*/}
          </div>
        ))}
      </div>

      <div className=" p-3 text-center underline">
        <Link to="/cart">View and Edit Cart</Link>
      </div>
    </div>
  );
}
