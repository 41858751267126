import { useFormContext } from "react-hook-form";

function FormInput({
  className,
  placeholder,
  type,
  name,
  autoComplete,
  autoFocus,
  title,
  validationSchema,
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="w-full">
      <label className='block text-black font-normal text-base md:text-xl mb-2' htmlFor="name">
        {title}
      </label>
      <input
        className={`${errors[name]?.message ? "border border-red-700" : "border-0"
          } bg-[#EBEBEB] p-2 md:p-4 w-full outline-none shadow-lg ${className}`}
        placeholder={placeholder ?? ""}
        type={type ?? "text"}
        autoComplete={autoComplete || "on"}
        autoFocus={autoFocus ?? false}
        {...register(name, validationSchema ?? undefined)}
      />
      {errors[name]?.message && (
        <div className="mt-1 text-sm text-red-700">{`${errors[name]?.message}`}</div>
      )}
    </div>
  );
}

export default FormInput;
