import React from 'react'
import ProductFilter from '../components/ProductFilter'

function Accessories() {
  return (
    <>
      <ProductFilter heading={"Accessories"} />
    </>
  )
}

export default Accessories
