import React, { useContext, useState } from "react";
import Swal from "sweetalert2";
import WarningImageBanner from "../../images/improvementImage.jpg";
import myContext from "../../data/myContext";
import FormInput from "../controls/FormInput";
import { FormProvider, useForm } from "react-hook-form";
import { australianStatesAndTerritories } from "../../Pages/PaymentPage";
import Loader from "../controls/Loader";
// import { StaticImage } from 'gatsby-plugin-image';
// import WhatsAppIcon from '../WhatsappIcon';

const Tabs = () => {
    const context = useContext(myContext);
    const {
        users,
        setUsers,
        signUpUser,
        signInUserCredentials,
        signInUser,
        setSignInUserCreadentials,
    } = context;
    const [activeTab, setActiveTab] = useState("login");

    const methods = useForm({
        defaultValues: activeTab === "login" ? {
            email: "",
            password: "",
        } : {
            firstName: "",
            LastName: "",
            streetAddress: "",
            suburb: "",
            state: "",
            postCode: "",
        },
    });
    const {
        register,
        watch,
        reset,
        formState: { errors, isSubmitting, isValid },
    } = methods;

    // const[lo]

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <>
            <div className="container mx-auto">
                <div className="justify-center mt-28 w-full lg:w-[60%] mx-auto">
                    <div className="flex">
                        <div className="mx-auto flex gap-x-24">
                            <div
                                className={`cursor-pointer font-bold text-2xl md:text-5xl lg:text-7xl ${activeTab === "login" ? "text-black" : "text-[#DEDEDE]"
                                    }`}
                                onClick={() => handleTabClick("login")}
                            >
                                Login
                            </div>
                            <div
                                className={`cursor-pointer font-bold text-2xl md:text-5xl lg:text-7xl ${activeTab === "register" ? "text-black" : "text-[#DEDEDE]"
                                    }`}
                                onClick={() => handleTabClick("register")}
                            >
                                Register
                            </div>
                        </div>
                    </div>

                    <div className="p-4 rounded-b">
                        {activeTab === "login" && (
                            <FormProvider {...methods}>
                                <form onSubmit={methods.handleSubmit(signInUser)} className="flex flex-col gap-5">
                                    <FormInput
                                        name="email"
                                        title="Email *"
                                        validationSchema={{
                                            required: "Email is required",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "invalid email address",
                                            },
                                        }}
                                    />

                                    <FormInput
                                        name="password"
                                        type="password"
                                        title="Password *"
                                        validationSchema={{ required: "Password is required" }}
                                    />

                                    <button
                                        type="submit"
                                        className="w-full py-2 bg-black text-white rounded"
                                    >
                                        {isSubmitting ? <Loader color="white" size="sm" /> : "Login"}
                                    </button>

                                    <p className="text-black text-base md:text-xl font-semibold text-center my-4">
                                        Lost your password?
                                    </p>
                                </form>
                            </FormProvider>
                        )}

                        {activeTab === "register" && (
                            <FormProvider {...methods}>
                                <form onSubmit={methods.handleSubmit(signUpUser)} className="flex flex-col gap-5" >

                                    <div className="flex items-center gap-4">
                                        <FormInput
                                            name="firstName"
                                            title="First Name"
                                            validationSchema={{
                                                required: "First name is required",
                                            }}
                                        />

                                        <FormInput
                                            name="LastName"
                                            title="Last Name"
                                            validationSchema={{
                                                required: "Last name is required",
                                            }}
                                        />
                                    </div>

                                    <FormInput
                                        name="streetAddress"
                                        title="Street Address"
                                        validationSchema={{
                                            required: "Street address is required",
                                        }}
                                    />

                                    <FormInput
                                        name="suburb"
                                        title="Suburb"
                                        validationSchema={{
                                            required: "Suburb is required",
                                        }}
                                    />

                                    <div className='w-full'>
                                        <label className='block text-black font-normal text-base md:text-xl mb-2' htmlFor="name">
                                            Select State
                                        </label>
                                        <select {...register("state", { required: "State is required" })} className={`${errors.state?.message ? "border border-red-700" : "border-0"
                                            } bg-[#EBEBEB] p-2 md:p-4 w-full outline-none shadow-lg`}>
                                            <option value="">Select State</option>
                                            {australianStatesAndTerritories?.map((e) => <option value={e}>{e}</option>)}
                                        </select>

                                        {errors.state && (
                                            <div className="text-sm text-red-700 ml-2">{`${errors.state?.message}`}</div>
                                        )}
                                    </div>

                                    <FormInput
                                        name="postCode"
                                        title="Post Code"
                                        validationSchema={{
                                            required: "Post code is required",
                                        }}
                                    />

                                    <FormInput
                                        name="userName"
                                        title="User Name"
                                        validationSchema={{
                                            required: "User name is required",
                                        }}
                                    />


                                    <FormInput
                                        name="email"
                                        title="Email *"
                                        validationSchema={{
                                            required: "Email is required",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "invalid email address",
                                            },
                                        }}
                                    />

                                    <FormInput
                                        name="password"
                                        type="password"
                                        title="Password *"
                                        validationSchema={{
                                            required: "Password is required", minLength: {
                                                value: 6,
                                                message: "Password should be atleast 6 characters"
                                            }
                                        }}
                                    />

                                    <button
                                        type="submit"
                                        className="w-full py-2 bg-black text-white rounded"
                                    >

                                        {isSubmitting ? <Loader color="white" size="sm" /> : "Register"}
                                    </button>
                                </form>
                            </FormProvider>
                        )}
                    </div>
                </div>

                <div className="container mx-auto px-4 lg:px-0 my-8 lg:my-16">
                    <img
                        className="my-12 px-6"
                        src={WarningImageBanner}
                        alt="Improvement"
                    />
                </div>

                {/* <IllegaToSellTobaco /> */}
            </div>
            {/* <WhatsAppIcon /> */}
        </>
    );
};

export default Tabs;
