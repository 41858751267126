import React, { useContext, useEffect, useState } from "react";
import { FireDB } from "../firebase";
import Swal from "sweetalert2";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/controls/Loader";
import myContext from "../data/myContext";

function calculateTotalPrice(data) {
  return data.reduce((subtotal, product) => {
    return subtotal + parseFloat(product.price) * product.count;
  }, 0);
}

export const australianStatesAndTerritories = [
  "Australian Capital Territory",
  "New South Wales",
  "Northern Territory",
  "Queensland",
  "South Australia",
  "Tasmania",
  "Victoria",
  "Western Australia",
];

function PaymentPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const cartItem = state.cartItem;

  const context = useContext(myContext);
  const { setCartItem } = context;

  const user = localStorage.getItem("userAuth");
  const localUser = user ? JSON.parse(user) : null;

  useEffect(() => {
    const total = calculateTotalPrice(cartItem);
    console.log(total);
    if (!cartItem) {
      navigate(-1);
    }
  }, [cartItem]);

  const [isOpenShipping, setOpenShipping] = useState(true);
  const [isOpenBilling, setOpenBilling] = useState(false);
  const [isOpenPayment, setOpenPayment] = useState(true);

  const [isBank, setIsBank] = useState(true);

  const [shippingForm, setShippingForm] = useState({
    name: "",
    address: "",
    city: "",
    phone: "",
    state: "",
    country: "",
    appartment: "",
  });
  const [billingForm, setBillingForm] = useState({
    name: "",
    address: "",
    city: "",
    phone: "",
    state: "",
    country: "",
    appartment: "",
  });
  const [paymentForm, setPaymentForm] = useState({
    creditcardnumber: "",
    expiration: "",
    nameofcard: "",
    cvv: "",
  });

  const methods = useForm({
    defaultValues: {
      shipping: {
        name: "",
        address: "",
        city: "",
        phone: "",
        state: "",
        country: "Australia",
        appartment: "",
        zipCode: "",
      },
      billing: {
        name: "",
        address: "",
        city: "",
        phone: "",
        state: "",
        country: "Australia",
        appartment: "",
        zipCode: "",
      },
      payment: {
        creditCardNumber: "",
        expiration: "",
        cardName: "",
        cvv: "",
      },
      agreeTerms: false,
    },
  });

  const {
    register,
    watch,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = methods;

  async function onSubmit(formData) {
    delete formData.agreeTerms;
    try {
      console.log(formData);
      const orderRef = doc(collection(FireDB, "orders"));

      await setDoc(orderRef, {
        ...formData,
        id: orderRef.id,
        createdAt: new Date(),
        updatedAt: new Date(),
        products: cartItem,
        userId: localUser.id,
        email: localUser.email,
        customerName: `${localUser.firstName} ${localUser.LastName}`,
        paymentMethod: isBank ? "Bank" : "Card",
        totalPrice: calculateTotalPrice(cartItem),
      });
      setCartItem([]);
      Swal.fire({
        title: "Thanks, your order has been placed!",
        html: `<div>Your order number is <b>#${
          orderRef?.id.slice(0, 5) || "427647"
        }</b>.</ br> Your order was sent to us but is currently awaiting payment. Once we receive the payment for your order, We will process your order. Must include your order number (<b>${
          orderRef?.id.slice(0, 5) || "427647"
        }</b>) in reference of your transaction.</div>`,
        icon: "success",
      }).then((e) => (window.location.href = "/"));
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error?.message || "Something went wrong!",
      });
    }
  }

  return (
    <>
      <div className="payment-page" style={{ padding: "16px" }}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          style={{
            // backgroundColor: "#ffffff",
            // borderRadius: "8px",
            // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            display: "flex",
            // sm:{"display":"flex",flexDirection:"column"}
            // padding: "24px",
          }}
        >
          <div style={{ flex: 1, paddingRight: "20px" }}>
            <div className="flex flex-col">
              <div
                className="flex items-center gap-5 cursor-pointer"
                onClick={() => setOpenShipping(!isOpenShipping)}
              >
                <h2
                  className="text-3xl font-bold cursor-pointer"
                  // onClick={() => setOpenShipping(!isOpenShipping)}
                >
                  Shipping
                </h2>
                <div
                  className="cursor-pointer"
                  // onClick={() => setOpenShipping(!isOpenShipping)}
                >
                  {isOpenShipping ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 15l7-7 7 7"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  )}
                </div>
              </div>

              {isOpenShipping && (
                <div className="bg-white py-4 w-full">
                  {/* <form onSubmit={methods.handleSubmit(onSubmit)}> */}
                  {/* <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">Address:</label> */}

                  <div className="lg:flex gap-2">
                    <div className="w-full">
                      <input
                        {...register("shipping.name", {
                          required: "Name is required",
                        })}
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mt-1"
                        placeholder="Full Name"
                      />
                      {errors.shipping?.name && (
                        <div className="text-sm text-red-700 ml-2">{`${errors.shipping?.name?.message}`}</div>
                      )}
                    </div>
                    <div className="w-full">
                      <input
                        {...register("shipping.address", {
                          required: "Address is required",
                        })}
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 my-1"
                        placeholder="Address"
                      />
                      {errors.shipping?.address && (
                        <div className="text-sm text-red-700 ml-2">{`${errors.shipping?.address?.message}`}</div>
                      )}
                    </div>
                  </div>
                  <div className="lg:flex gap-2">
                    <div className="w-full">
                      <input
                        {...register("shipping.city", {
                          required: "City is required",
                        })}
                        type="text"
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mt-4"
                        placeholder="City"
                      />
                      {errors.shipping?.city && (
                        <div className="text-sm text-red-700 ml-2">{`${errors.shipping?.city?.message}`}</div>
                      )}
                    </div>
                    <div className="w-full">
                      <input
                        {...register("shipping.phone", {
                          required: "phone is required",
                        })}
                        type="text"
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mt-4"
                        placeholder="Phone"
                      />
                      {errors.shipping?.phone && (
                        <div className="text-sm text-red-700 ml-2">{`${errors.shipping?.phone?.message}`}</div>
                      )}
                    </div>
                  </div>
                  <div className="lg:flex gap-2">
                    <div className="w-full">
                      <select
                        {...register("shipping.state", {
                          required: "State is required",
                        })}
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mt-4"
                      >
                        <option value="">Select State</option>
                        {australianStatesAndTerritories?.map((e) => (
                          <option value={e}>{e}</option>
                        ))}
                      </select>
                      {/* <input {...register("shipping.state", { required: "State is required" })} type="text" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mt-4" placeholder='State/Province' /> */}
                      {errors.shipping?.state && (
                        <div className="text-sm text-red-700 ml-2">{`${errors.shipping?.state?.message}`}</div>
                      )}
                    </div>
                    <div className="w-full">
                      <input
                        value="Australia"
                        disabled
                        type="text"
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mt-4"
                        placeholder="Country"
                      />
                    </div>
                  </div>

                  <div className="lg:flex gap-2">
                    <div className="w-full">
                      <input
                        {...register("shipping.appartment")}
                        type="text"
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 my-4"
                        placeholder="Appartment (Optional)"
                      />
                      {errors.shipping?.appartment && (
                        <div className="text-sm text-red-700 ml-2">{`${errors.shipping?.appartment?.message}`}</div>
                      )}
                    </div>
                    <div className="w-full">
                      <input
                        {...register("shipping.zipCode", {
                          required: "Zip code is required",
                        })}
                        type="text"
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mt-4"
                        placeholder="Zip Code"
                      />
                      {errors.shipping?.zipCode && (
                        <div className="text-sm text-red-700 ml-2">{`${errors.shipping?.zipCode?.message}`}</div>
                      )}
                    </div>
                  </div>
                  {/* <button className='invisible' type='submit'>submit</button> */}
                  {/* </form> */}
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      id="save_as_shipping"
                      onChange={(e) => {
                        if (e.target.checked) {
                          return reset({
                            billing: {
                              name: watch("shipping.name"),
                              address: watch("shipping.address"),
                              appartment: watch("shipping.appartment"),
                              city: watch("shipping.city"),
                              phone: watch("shipping.phone"),
                              state: watch("shipping.state"),
                              country: watch("shipping.country"),
                              zipCode: watch("shipping.zipCode"),
                              appartment: watch("shipping.appartment"),
                            },
                          });
                        }
                        reset({
                          billing: {
                            name: "",
                            address: "",
                            appartment: "",
                            city: "",
                            country: "Australia",
                            phone: "",
                            state: "",
                          },
                        });
                      }}
                      style={{
                        width: "1rem",
                        height: "1rem",
                        cursor: "pointer",
                      }}
                    />
                    <label
                      htmlFor="save_as_shipping"
                      className="text-sm"
                      style={{
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Shipping detail as a billing
                    </label>
                  </div>
                </div>
              )}
            </div>

            <div className="flex flex-col mt-10">
              {/* <h2
                className="text-3xl font-bold cursor-pointer"
                onClick={() => setOpenBilling(!isOpenBilling)}
              >
                Billing
              </h2> */}

              <div
                className="flex items-center gap-5 cursor-pointer"
                onClick={() => setOpenBilling(!isOpenBilling)}
              >
                <h2
                  className="text-3xl font-bold cursor-pointer"
                  // onClick={() => setOpenBilling(!isOpenBilling)}
                >
                  Billing
                </h2>
                <div
                  className="cursor-pointer"
                  // onClick={() => setOpenShipping(!isOpenBilling)}
                >
                  {isOpenBilling ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 15l7-7 7 7"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  )}
                </div>
              </div>

              {isOpenBilling && (
                <div className="bg-white py-4 w-full">
                  {/* <form onSubmit={methods.handleSubmit(onSubmit)}> */}
                  {/* <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">Address:</label> */}
                  <div className="lg:flex gap-2">
                    <div className="w-full">
                      <input
                        {...register("billing.name", {
                          required: "Name is required",
                        })}
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mt-4"
                        placeholder="Full Name"
                      />
                      {errors.billing?.name && (
                        <div className="text-sm text-red-700 ml-2">{`${errors.billing?.name?.message}`}</div>
                      )}
                    </div>

                    <div className="w-full">
                      <input
                        {...register("billing.address", {
                          required: "Address is required",
                        })}
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mt-4"
                        placeholder="Address"
                      />
                      {errors.billing?.address && (
                        <div className="text-sm text-red-700 ml-2">{`${errors.billing?.address?.message}`}</div>
                      )}
                    </div>
                  </div>
                  <div className="lg:flex gap-2">
                    <div className="w-full">
                      <input
                        {...register("billing.city", {
                          required: "City is required",
                        })}
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mt-4"
                        placeholder="City"
                      />
                      {errors.billing?.city && (
                        <div className="text-sm text-red-700 ml-2">{`${errors.billing.city?.message}`}</div>
                      )}
                    </div>

                    <div className="w-full">
                      <input
                        {...register("billing.phone", {
                          required: "Phone is required",
                        })}
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mt-4"
                        placeholder="Phone"
                      />
                      {errors.billing?.phone && (
                        <div className="text-sm text-red-700 ml-2">{`${errors.billing.phone?.message}`}</div>
                      )}
                    </div>
                  </div>

                  <div className="lg:flex gap-2">
                    <div className="w-full">
                      <input
                        {...register("billing.state", {
                          required: "State is required",
                        })}
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mt-4"
                        placeholder="State/Province"
                      />
                      {errors.billing?.state && (
                        <div className="text-sm text-red-700 ml-2">{`${errors.billing.state?.message}`}</div>
                      )}
                    </div>

                    <div className="w-full">
                      <input
                        {...register("billing.country", {
                          required: "Country is required",
                        })}
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mt-4"
                        placeholder="Country"
                      />
                      {errors.billing?.country && (
                        <div className="text-sm text-red-700 ml-2">{`${errors.billing.country?.message}`}</div>
                      )}
                    </div>
                  </div>

                  <div className="lg:flex gap-2">
                    <div className="w-full">
                      <input
                        {...register("billing.appartment")}
                        type="text"
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 my-4"
                        placeholder="Appartment (Optional)"
                      />
                      {errors.billing?.appartment && (
                        <div className="text-sm text-red-700 ml-2">{`${errors.billing?.appartment?.message}`}</div>
                      )}
                    </div>
                    <div className="w-full">
                      <input
                        {...register("billing.zipCode", {
                          required: "Zip code is required",
                        })}
                        type="text"
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mt-4"
                        placeholder="Zip Code"
                      />
                      {errors.billing?.zipCode && (
                        <div className="text-sm text-red-700 ml-2">{`${errors.billing?.zipCode?.message}`}</div>
                      )}
                    </div>
                  </div>

                  {/* <button className='invisible' type='submit'>submit</button> */}
                  {/* </form> */}
                </div>
              )}
            </div>
          </div>

          <div style={{ flex: 1, paddingRight: "20px" }}>
            <div className="flex flex-col">
              <h2
                className="text-3xl font-bold cursor-pointer"
                // onClick={() => setOpenPayment(!isOpenPayment)}
              >
                Payment
              </h2>
              {isOpenPayment && (
                <div className="bg-white py-4 w-full">
                  {/* <div
                    className="w-fit border cursor-pointer border-slate-300 bg-slate-100 px-3 rounded active:scale-[.98]"
                    onClick={() => setIsBank(!isBank)}
                  >
                    {!isBank ? "Bank Transfer" : "Payment with Card"}{" "}
                  </div> */}

                  <div style={{ display: "flex" }}>
                    <button
                      style={{
                        cursor: "pointer",
                        padding: "10px 20px",
                        border: "1px solid transparent",
                        borderRadius: "4px",
                        backgroundColor: !isBank ? "#fff" : "#19AEE8",
                        borderBottom: !isBank && "1px solid #fff",
                        color: isBank ? "white" : "black",
                      }}
                      onClick={() => setIsBank(true)}
                    >
                      Bank Transfer
                    </button>
                    <button
                      style={{
                        cursor: "pointer",
                        padding: "10px 20px",
                        border: "1px solid transparent",
                        borderRadius: "4px",
                        backgroundColor: isBank ? "#fff" : "#19AEE8",
                        borderBottom: isBank && "1px solid #fff",
                        color: isBank ? "black" : "white",
                      }}
                      onClick={() => setIsBank(false)}
                    >
                      Payment with Card
                    </button>
                  </div>

                  {!isBank ? (
                    <div>
                      {/* <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">Address:</label> */}

                      <div className="lg:flex gap-2">
                        <div className="w-full">
                          <input
                            {...register(
                              "payment.creditCardNumber",
                              !isBank && { required: "Card number is required" }
                            )}
                            className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 my-4"
                            placeholder="Credit Card Number"
                          />
                          {errors.payment?.creditCardNumber && (
                            <div className="text-sm text-red-700 ml-2">{`${errors.payment.creditCardNumber?.message}`}</div>
                          )}
                        </div>

                        <div className="w-full">
                          <input
                            {...register(
                              "payment.expiration",
                              !isBank && { required: "Expiration is required" }
                            )}
                            className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 my-4"
                            placeholder="Expiration"
                          />
                          {errors.payment?.expiration && (
                            <div className="text-sm text-red-700 ml-2">{`${errors.payment?.expiration?.message}`}</div>
                          )}
                        </div>
                      </div>
                      <div className="lg:flex gap-2">
                        <div className="w-full">
                          <input
                            {...register("payment.cardName", {
                              required: "Name on Card is required",
                            })}
                            className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 my-1"
                            placeholder="Name on Card"
                          />
                          {errors.payment?.cardName && (
                            <div className="text-sm text-red-700 ml-2">{`${errors.payment?.cardName?.message}`}</div>
                          )}
                        </div>
                        <div className="w-full">
                          <input
                            {...register("payment.cvv", {
                              required: "CVV is required",
                            })}
                            className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-md py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 my-1"
                            placeholder="CVV"
                          />
                          {errors.payment?.cvv && (
                            <div className="text-sm text-red-700 ml-2">{`${errors.payment?.cvv?.message}`}</div>
                          )}
                        </div>
                      </div>
                      {/* <div className='lg:flex gap-2'>
                  <input type="text" id="state" name="state" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 my-4" placeholder='State/Province' />

                  <input type="text" id="country" name="country" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 my-4" placeholder='Country' />
                </div>

                <input type="text" id="appartment" name="appartment" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 my-4" placeholder='Appartment (Optional)' /> */}

                      {/* <button type="submit" className="w-full bg-indigo-500 text-white py-2 px-4 rounded-md hover:bg-indigo-600">Submit</button> */}
                    </div>
                  ) : (
                    <div className="pl-2 my-3 flex  flex-col gap-3">
                      <hr />
                      <div className="font-semibold text-lg">
                        Bank Transfer Details
                      </div>
                      <div>
                        Bank Name: <span className="font-medium">ANZ</span>
                      </div>
                      <div>
                        Account Name:{" "}
                        <span className="font-medium">VKING AUSTRALIA</span>
                      </div>
                      <div>
                        BSB: <span className="font-medium">012257</span>
                      </div>
                      <div>
                        Acc Number:{" "}
                        <span className="font-medium"> 310700002</span>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            <h2 className="text-3xl mb-4 cursor-pointer">Terms & Conditions</h2>
            <div className="">
              <div className="max-h-40 overflow-y-auto">
                <textarea
                  rows={5}
                  className="w-full h-full border-none rounded-md px-2 resize-none focus:border-none focus:outline-none"
                  value="I declare that I am at least 18 years of age.

                I agree to the Shipping Conditions set out on the Shipping Information page.
                
                I understand that choosing a Shipping Method which does not request a Signature will give Australia Post the Authority to Leave.
                
                
                I agree to the Warranty Conditions set out on the Warranty Information page."
                  readOnly
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "300",
                  }}
                />
              </div>
            </div>

            <div className="mt-1 flex items-center">
              <input
                type="checkbox"
                id="terms"
                className="mr-2 checkbox-large"
                {...register("agreeTerms", {
                  required: "Terms and conditions is required",
                })}
                style={{ width: "1rem", height: "1rem", cursor: "pointer" }}
              />
              <label
                htmlFor="terms"
                className="text-sm text-gray-700"
                style={{
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                I agree to the terms and conditions
              </label>
              {errors.agreeTerms && (
                <div className="text-sm text-red-700 ml-2">{`${errors.agreeTerms?.message}`}</div>
              )}
            </div>

            <button
              // disabled={isValid}
              onClick={() => {
                setOpenShipping(true);
                setOpenBilling(true);
                setOpenPayment(true);
              }}
              type="submit"
              className={`w-full bg-black text-white py-2 px-4 my-6 rounded-md hover:bg-blue-600 ${
                !watch("agreeTerms") && "opacity-50 cursor-not-allowed"
              }`}
            >
              {isSubmitting ? (
                <Loader color={"white"} size={"sm"} />
              ) : (
                "Place Order"
              )}
            </button>
          </div>
        </form>
      </div>

      <style jsx>{`
        .payment-page {
          margin: 0; /* Reset margin */
        }

        @media (min-width: 768px) {
          .payment-page {
            margin: 10px 100px 20px 100px; /* Apply margin on big screens */
          }
        }

        @media (max-width: 768px) {
          form {
            display: flex;
            flex-direction: column;
          }
          .payment-page {
            margin: 10px; /* Apply margin on small screens */
          }
        }
      `}</style>
    </>
  );
}

export default PaymentPage;
