import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo.png";
import myContext from "../../data/myContext";
import CartPopup from "./CartPopup";

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const context = useContext(myContext);
  const { cartItem } = context;

  const [isOpen, setIsOpen] = useState(false);
  const [isClickedCart, setIsClickedCart] = useState(false);

  const user = localStorage.getItem("userAuth");
  const localUser = user ? JSON.parse(user) : null;
  console.log(isOpen);
  return (
    <nav className="bg-[#040608] p-4 top-0 z-50">
      <div className="container mx-auto flex items-center justify-between gap-2">
        <div className="flex items-center">
          <Link to="/" className="text-white text-lg font-bold">
            <img className="w-48 lg:w-56" src={Logo} alt="Logo" />
          </Link>
        </div>

        {/* Desktop navigation */}
        <div className="hidden xl:flex space-x-4 text-white font-bold text-lg">
          <Link to="/">Home</Link>
          <Link to="/brands">Brands</Link>
          <Link to="/cigarettes">Cigarettes</Link>
          <Link to="/cigar">Cigars</Link>
          <Link to="/ryo">RYO</Link>
          {/* <Link to="/vaping">Vapes</Link> */}
          <Link to="/accessories">Accessories</Link>
          <Link to="/about">About us</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/terms">Terms & Conditions</Link>
        </div>

        {/* Icons section */}
        <div className="hidden cursor-pointer xl:flex items-center space-x-4 relative">
          <div
            className="flex gap-1"
            onClick={() => setIsOpen(!isOpen)}
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6 text-white"
              onClick={() => setIsClickedCart(!isClickedCart)}
            >
              <path
                fillRule="evenodd"
                d="M7.5 6v.75H5.513c-.96 0-1.764.724-1.865 1.679l-1.263 12A1.875 1.875 0 0 0 4.25 22.5h15.5a1.875 1.875 0 0 0 1.865-2.071l-1.263-12a1.875 1.875 0 0 0-1.865-1.679H16.5V6a4.5 4.5 0 1 0-9 0ZM12 3a3 3 0 0 0-3 3v.75h6V6a3 3 0 0 0-3-3Zm-3 8.25a3 3 0 1 0 6 0v-.75a.75.75 0 0 1 1.5 0v.75a4.5 4.5 0 1 1-9 0v-.75a.75.75 0 0 1 1.5 0v.75Z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-xl font-medium text-white">
              {cartItem.length}
            </span>
            {cartItem.length > 0 && isOpen && <CartPopup />}
            {cartItem.length > 0 && isClickedCart && <CartPopup />}
            {cartItem.length <= 0 && isOpen && (
              <div className="z-50 origin-top-right absolute right-0 mt-14 w-80 md:w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-2 text-lg">
                Cart is empty
              </div>
            )}
          </div>
          <Link to={localUser ? "/my-account" : "/signin"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6 text-white"
            >
              <path
                fillRule="evenodd"
                d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                clipRule="evenodd"
              />
            </svg>
          </Link>
        </div>

        {/* Mobile menu toggler */}

        <div className="xl:hidden flex gap-2">
          <Link className="flex" to="/cart">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6 text-white"
            >
              <path
                fillRule="evenodd"
                d="M7.5 6v.75H5.513c-.96 0-1.764.724-1.865 1.679l-1.263 12A1.875 1.875 0 0 0 4.25 22.5h15.5a1.875 1.875 0 0 0 1.865-2.071l-1.263-12a1.875 1.875 0 0 0-1.865-1.679H16.5V6a4.5 4.5 0 1 0-9 0ZM12 3a3 3 0 0 0-3 3v.75h6V6a3 3 0 0 0-3-3Zm-3 8.25a3 3 0 1 0 6 0v-.75a.75.75 0 0 1 1.5 0v.75a4.5 4.5 0 1 1-9 0v-.75a.75.75 0 0 1 1.5 0v.75Z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-xl font-medium text-white">
              {cartItem.length}
            </span>
          </Link>
          <Link to={localUser ? "/my-account" : "/signin"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6 text-white"
            >
              <path
                fillRule="evenodd"
                d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                clipRule="evenodd"
              />
            </svg>
          </Link>

          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M3 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 5.25Zm0 4.5A.75.75 0 0 1 3.75 9h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 9.75Zm0 4.5a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Zm0 4.5a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="xl:hidden absolute z-50 top-[80px] left-0 w-full bg-[#1bace5] shadow-lg rounded-b-2xl -mt-[15px]">
            <div className="flex flex-col space-y-4 p-4 text-white items-center">
              <Link to="/" onClick={toggleMenu}>
                Home
              </Link>
              <Link to="/brands" onClick={toggleMenu}>
                Brands
              </Link>
              <Link to="/cigarettes" onClick={toggleMenu}>
                Cigarettes
              </Link>
              <Link to="/cigar" onClick={toggleMenu}>
                Cigars
              </Link>
              <Link to="/ryo" onClick={toggleMenu}>
                RYO
              </Link>
              {/* <Link to="/vaping" onClick={toggleMenu}>
                Vapes
              </Link> */}
              <Link to="/accessories" onClick={toggleMenu}>
                Accessories
              </Link>
              <Link to="/about" onClick={toggleMenu}>
                About us
              </Link>
              <Link to="/contact" onClick={toggleMenu}>
                Contact
              </Link>
              <Link to="/terms" onClick={toggleMenu}>
                TERMS & CONDITION
              </Link>
            </div>
            <div className="my-4 w-[25%] mx-auto xl:hidden flex items-center space-x-4">
              <Link className="flex gap-1" to="/cart" onClick={toggleMenu}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6 text-white"
                >
                  {/* Your SVG path */}
                </svg>
              </Link>
              <Link to="/signin" onClick={toggleMenu}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6 text-white"
                >
                  {/* Your SVG path */}
                </svg>
              </Link>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
