import React from 'react'
import ProductFilter from '../components/ProductFilter'

function Cigarettes() {
  return (
    <>
      <ProductFilter heading={"Cigarettes"} />
    </>
  )
}

export default Cigarettes
