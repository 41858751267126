import React from "react";
import AuthTab from "../components/AuthTabs/Tabs";

function SignIn() {
  return (
    <div>
      <AuthTab />
    </div>
  );
}

export default SignIn;
