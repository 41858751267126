import { signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FireDB, auth } from "../firebase";
import Swal from "sweetalert2";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";

import FormInput from "../components/controls/FormInput";
import { FormProvider, useForm } from "react-hook-form";
import { australianStatesAndTerritories } from "../Pages/PaymentPage";
import Loader from "../components/controls/Loader";

const sideBar = ["Dashboard", "Order", "Address", "Account Details", "Logout"];

export default function MyAccountPage() {
  const [currentTab, setCurrentTab] = useState("Dashboard");
  const [orders, setOrders] = useState();

  const user = localStorage.getItem("userAuth");
  const localUser = user ? JSON.parse(user) : null;

  const methods = useForm({
    defaultValues: {
      firstName: localUser.firstName,
      LastName: localUser.LastName,
      streetAddress: localUser.streetAddress,
      suburb: localUser.suburb,
      state: localUser.state,
      postCode: localUser.postCode,
      userName: localUser.userName,
      email: localUser.email,
    },
  });

  const {
    register,
    watch,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = methods;

  // console.log(localUser);

  const navigate = useNavigate();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        localStorage.removeItem("userAuth");
        navigate("/");
        // console.log("Signed out successfully");
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Logout successfully! ",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        // An error happened.
      });
  };

  async function getOrder() {
    // const orderRef = orderBy(sortByFieldName ?? 'updatedAt', 'desc');
    const q = query(
      collection(FireDB, "orders"),
      where("userId", "==", localUser.id)
      // orderBy("updatedAt", "desc")
      // limit(loadMore!)
    );

    let error = null;
    const data = [];

    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.data());
        data.push(doc.data());
      });

      // console.log("data", data);
      setOrders(data);
    } catch (e) {
      error = e;
    }

    return { data, error };
  }

  const updateUser = async (e) => {
    try {
      const userRef = doc(FireDB, "users", localUser.id);
      await setDoc(
        userRef,
        {
          ...e,
          updatedAt: new Date(),
        },
        { merge: true }
      );

      // localStorage.setItem(
      //   "userAuth",
      //   JSON.stringify({
      //     ...e,
      //     updatedAt: new Date(),
      //   })
      // );

      const user = await getDoc(doc(FireDB, "users", localUser.id));
      localStorage.setItem("userAuth", JSON.stringify(user.data()));

      await Swal.fire({
        position: "center",
        icon: "success",
        title: "Updated successfully",
        showConfirmButton: true,
        timer: 3000,
      });
    } catch (error) {
      return Swal.fire({
        position: "center",
        icon: "Error",
        title: `Failed ${error?.message}`,
        showConfirmButton: true,
        timer: 3000,
      });
    }
  };

  useEffect(() => {
    void getOrder();
  }, []);

  return (
    <div className="grid grid-cols-3 max-w-screen-lg mx-auto my-10 gap-5">
      <div className="col-span-1 ">
        {sideBar.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              setCurrentTab(item);
              item === "Logout" && handleLogout();
            }}
            className={`${
              currentTab === item && "text-blue-400"
            } border-y p-2 cursor-pointer`}
          >
            {item}
          </div>
        ))}
      </div>
      <div className="col-span-2 ">
        {currentTab === "Dashboard" && (
          <div>
            <div>
              Hello {localUser.userName} (not {localUser.userName}?{" "}
              <span
                className="cursor-pointer font-bold underline"
                onClick={() => handleLogout()}
              >
                Log out
              </span>
              )
            </div>
            <div className="mt-5">
              From your account dashboard you can view your recent orders,
              manage your shipping and billing addresses, and edit your password
              and account details.
            </div>
          </div>
        )}

        {currentTab === "Order" && (
          <div className="">
            {orders && orderBy.length > 0
              ? orders.map((item, index) => (
                  <div className="border p-5 font-bold rounded-lg shadow-lg">
                    <p className="font-bold text-xl mb-2">
                      Order # {index + 1}
                    </p>
                    <p>
                      Order By :{" "}
                      <span className="font-normal">{item.customerName}</span>
                    </p>
                    <div>
                      {" "}
                      Products :
                      {item.products.map((product, index) => (
                        <p className="ml-4 font-normal">
                          {index + 1}- {product.name}
                        </p>
                      ))}
                    </div>
                    <p>
                      Total Price :{" "}
                      <span className="font-normal">${item.totalPrice}</span>
                    </p>
                  </div>
                ))
              : "No order has been made yet "}
          </div>
        )}

        {currentTab === "Address" && (
          <div className="border p-5 font-bold rounded-lg shadow-lg">
            <p className="text-xl font-bold">Billing Address</p>
            <p>{localUser.userName}</p>
            <p>{localUser.streetAddress}</p>
            <p>{localUser.suburb}</p>
            <p>{localUser.state}</p>
            <p>{localUser.postCode}</p>
          </div>
        )}

        {currentTab === "Account Details" && (
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(updateUser)}
              className="flex flex-col gap-5"
            >
              <div className="flex items-center gap-4">
                <FormInput
                  name="firstName"
                  title="First Name"
                  // value={localUser.firstName}
                  validationSchema={{
                    required: "First name is required",
                  }}
                />

                <FormInput
                  name="LastName"
                  title="Last Name"
                  validationSchema={{
                    required: "Last name is required",
                  }}
                />
              </div>

              <FormInput
                name="streetAddress"
                title="Street Address"
                validationSchema={{
                  required: "Street address is required",
                }}
              />

              <FormInput
                name="suburb"
                title="Suburb"
                validationSchema={{
                  required: "Suburb is required",
                }}
              />

              <div className="w-full">
                <label
                  className="block text-black font-normal text-base md:text-xl mb-2"
                  htmlFor="name"
                >
                  Select State
                </label>
                <select
                  {...register("state", { required: "State is required" })}
                  className={`${
                    errors.state?.message ? "border border-red-700" : "border-0"
                  } bg-[#EBEBEB] p-2 md:p-4 w-full outline-none shadow-lg`}
                >
                  <option value="">Select State</option>
                  {australianStatesAndTerritories?.map((e) => (
                    <option value={e}>{e}</option>
                  ))}
                </select>

                {errors.state && (
                  <div className="text-sm text-red-700 ml-2">{`${errors.state?.message}`}</div>
                )}
              </div>

              <FormInput
                name="postCode"
                title="Post Code"
                validationSchema={{
                  required: "Post code is required",
                }}
              />

              <FormInput
                name="userName"
                title="User Name"
                validationSchema={{
                  required: "User name is required",
                }}
              />

              <FormInput
                name="email"
                title="Email *"
                validationSchema={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                }}
              />

              {/* <FormInput
                name="password"
                type="password"
                title="Password *"
                validationSchema={{
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password should be atleast 6 characters",
                  },
                }}
              /> */}

              <button
                type="submit"
                className="w-full py-2 bg-black text-white rounded"
              >
                {isSubmitting ? (
                  <Loader color="white" size="sm" />
                ) : (
                  "Save Changes"
                )}
              </button>
            </form>
          </FormProvider>
        )}
      </div>
    </div>
  );
}
